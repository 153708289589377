import React, {Suspense, useEffect} from 'react'
import {StartlibsProvider, Slot, willUseSharedState, useToggle, useTitle} from '@startlibs/core'
import {postFetcher, getFetcher} from '@startlibs/utils'
import {ThemeProvider, createGlobalStyle} from 'styled-components'
import {Router, navigate} from '@reach/router'
import StyledTheme from './UIConfig/StyledTheme'
import StartlibsConfig from './UIConfig/StartlibsConfig'
import {ShareLogin} from 'sharecomponents/src/pages/ShareLogin'
import {SharedStudy} from 'sharecomponents/src/pages/SharedStudy'
import {willUseSuspense, useSuspense} from 'sharecomponents/src/hooks/useSuspense'
import {ProviderErrorBoundary} from 'sharecomponents/src/pages/errors/ProviderError'
import {useProviderInfo} from 'sharecomponents/src/hooks/useProviderInfo'
import {Loading, Dialog} from '@startlibs/components'
import styled from 'styled-components'
import {Notifications} from 'sharecomponents/src/components/Notifications'
import {ShareDialog} from 'sharecomponents/src/pages/ShareDialog'
import {formFetchWithBearer, postFetcherWithBearer} from 'sharecomponents/src/utils/authFetch'
import {addStudyDescription} from 'sharecomponents/src/utils'
import {ReportViewer} from './pages/ReportViewer'
import {useNotification} from 'sharecomponents/src/components/Notifications'
import {EmbeddedSuccessDialog} from './components/EmbeddedSuccessDialog'
import {LoadingErrorDialog} from "sharecomponents/src/pages/LoadingErrorDialog";

export const LoadingPage = styled(({className}) => <div className={className}>
  <Loading size={40} borderWidth={6}/>
</div>)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  * {
    margin-left: auto;
    margin-right: auto;
  }
  img {
    max-width: 11rem;
    margin-bottom: 4rem;
  }
`

export const App = () => {

  const page = useToggle()

  const maxDuration = useToggle(1)
  useEffect(() => {
    setTimeout(() => maxDuration.openWith(10000), 1000)
  }, [])

  useEffect(() => {
    window.addEventListener('message', (e) => {
      const data = typeof e.data === 'string' ? JSON.parse(e.data) : e.data
      if (data.type === 'blank') {
        navigate('/blank/')
      }
      if (['blank', 'loading', 'error', 'share'].indexOf(data.type) >= 0) {
        page.openWith(data)
      }
    }, false)
    window.parent.postMessage(JSON.stringify({type: 'loaded'}), '*')
  }, [])

  return <StartlibsProvider value={StartlibsConfig}>
    <ThemeProvider theme={StyledTheme}>
      <>
        <ProviderErrorBoundary isDialog={window.location.pathname.indexOf("/share/")<0}>
          <Suspense fallback={<LoadingPage/>} maxDuration={maxDuration.isOpen}>
            <Router component={Identity}>
              <Shared path="/share/*"/>
              <Viva path="/vivashare/:jwt"/>
              <Viva path="/loading"/>
              <Error path="/error"/>
              <ReportViewer path="/report"/>
              {
                !page.isOpen
                ? <Blank path="/blank"/>
                : ((page.isOpen.type === 'blank' && <Blank default/>) ||
                (page.isOpen.type === 'loading' && <Viva default/>) ||
                (page.isOpen.type === 'error' && <Error default errorStatus={page.isOpen.errorStatus}/>) ||
                (page.isOpen.type === 'share' && <Viva default jwt={page.isOpen.jwt}/>))
              }
            </Router>
          </Suspense>
        </ProviderErrorBoundary>
        <Notifications/>
        <div style={{position: 'absolute', top: 0, left: 0, right: 0, zIndex: 100}}>
          <Slot name="Popup"/>
        </div>
        <div style={{position: 'absolute', top: 0, left: 0, right: 0, zIndex: 200}}>
          <Slot name="Portal"/>
          <Slot name="Dialog"/>
          <Slot name="Confirm-Dialog"/>
          <Slot name="Notification"/>
          <Slot name="Notifications"/>
        </div>
      </>
    </ThemeProvider>
  </StartlibsProvider>
}

const Identity = React.forwardRef(({children}, ref) => <React.Fragment>{children}
  <div ref={ref}/>
</React.Fragment>)
const Blank = () => {
  useEffect(() => {
    const ref = document.getElementById('js-share-notification')
    if (ref) {
      const t = setTimeout(() => window.parent.postMessage(JSON.stringify({type: 'removeIframe'}), '*'), 5000)
      return () => window.clearTimeout(t)
    }
    window.parent.postMessage(JSON.stringify({type: 'removeIframe'}), '*')
  },[])
  return <div/>
}

const Error = ({errorStatus}) => {
  const dialog = useToggle(true)

  const closeDialog = React.useCallback(() => {
    dialog.close()
    setTimeout(() => window.parent.postMessage(JSON.stringify({type: 'removeIframe'}), '*'), 300)
  }, [])

  return dialog.isOpen && <LoadingErrorDialog
    errorCode={errorStatus || "01"}
    closeDialog={closeDialog}
  />
}

const useShareInfo = willUseSuspense((jwt) => getFetcher('/api/share/info', undefined, {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${jwt}`
  }
}))


const BodyBackgroundStyle = createGlobalStyle`
  body {
    background: rgba(0,0,0,0.05);;
  }
`

const Shared = () => {
  const jwt = window.location.pathname.replace(/^.*\/share\/([^/]+).*/, '$1')
  const [{providerInfo, authenticationResponse, ...shareInfo}] = useProviderInfo(useShareInfo(jwt))

  useTitle(providerInfo.name)

  return <>
    <BodyBackgroundStyle/>
    <Router component={Identity}>
      <ShareLogin path=":token" providerInfo={providerInfo} authenticationResponse={authenticationResponse}
                  dobSubmitFormat="yyyy/MM/dd"
                  shareInfo={shareInfo} successUrl={token => '/share/' + token + '/access'}/>
      <SharedStudy path=":token/access" providerInfo={providerInfo} viewReport={ReportViewer}/>
    </Router>
  </>
}

const Viva = ({jwt, location}) => {

  const query = new URLSearchParams(location && location.search)
  const isEmbedded = query.get('embedded') === 'true'
  const [notification, setNotification] = useNotification()

  const closingDialog = useToggle()
  const embeddedSuccessDialog = useToggle()

  const closeDialog = React.useCallback(() => {
    closingDialog.open()
    setTimeout(() => window.parent.postMessage(JSON.stringify({type: 'removeIframe'}), '*'), 300)
  }, [])

  const notifyAndClose = React.useCallback((notification) => {
    closingDialog.open()
    document.body.parentElement.style.overflow = 'hidden'
    setNotification(notification)
    setTimeout(() => {
      const ref = document.getElementById('js-share-notification')
      if (ref) {
        const {width, height, top, left} = ref.getBoundingClientRect()
        window.parent.postMessage(JSON.stringify({
          type: 'resizeIframe',
          width: width + 2,
          height: height + top + 4,
          left: left - 1,
          top: 0
        }), '*')
      }
    }, 300)
  }, [])

  useEffect(() => {
    if (closingDialog.isOpen && !notification) {
      setTimeout(() => window.parent.postMessage(JSON.stringify({type: 'removeIframe'}), '*'), 300)
    }
  }, [notification])

  if (closingDialog.isOpen) { return null }

  if (embeddedSuccessDialog.isOpen) {
    return <EmbeddedSuccessDialog message={embeddedSuccessDialog.isOpen}/>
  }

  return isEmbedded
         ? <ShareDialog
           notifyAndClose={embeddedSuccessDialog.openWith}
           jwt={jwt}
         />
         : <ShareDialog
           closeDialog={closeDialog}
           notifyAndClose={notifyAndClose}
           jwt={jwt}
         />
}