import React from 'react'
import {dicomDateToDate} from 'sharecomponents/src/utils'
import {Preview} from 'report-components/src/modules/Preview'
import {en_US} from 'report-components/src/components/date.i18n/en_US'
import {Timer} from '../components/Timer'
import {formatDate} from '@startlibs/utils'
import {Button} from '@startlibs/components'

export const ReportViewer = ({report, reportJWT, closeReport,openViewer}) => {
  /*

  reportSettings, reportTitle, availableSignatures,
  reportBasicInfos: infoItems(reportBasicInfos),
  reportFields: fieldItems(reportFields),
  reportSignatures: signatureItems(reportSignatures),
  reportKeyImages: getReportKeyImages(reportKeyImages),
  patientName: getPatientName({reportBasicInfos, dicomInfo}),
  finished,
  lastSaved,
  isPdfPrinter,
  getResource: getResource,
  setImagesLoaded: (v) => dispatch(imagesLoaded(v))
   */
  /*
    <a href={"/api/report/preview?t="+reportJWT}>preview</a>
    <a href={"/api/report/download?t="+reportJWT}>download</a>
    */

  return <>
  <div className="viva-navbar preview">
      <div className="navbar-content">
        <div className="left-container">
            <Button onClick={closeReport} outline white className="return-button" icon="arrow-left">Return</Button>
        </div>
        <div className="central-container">
          <div className="viva-logo">ViVA - Report Viewer</div>
        </div>
        <div className="right-container"></div>
      </div>
    </div>
    <div className="content viva-report-preview">
    <ReportHeader openViewer={openViewer} reportJWT={reportJWT} report={report}/>
    <Preview
      {...report}
      getResource={(uid) => '/api/report/resource?resourceUID=' + uid + '&t=' + reportJWT}
      setImagesLoaded={() => {}}
    />
   <div className="report-builder-footer"></div>
  </div>
  </>
}


const ReportHeader = ({report,reportJWT, openViewer}) => {
  const {language, dateFormat} = report.reportSettings.layout
  const {lastSaved, finished} = report
  const {studies, patientname} = report.dicomInfo
  return <div className="report-builder-header">
    <LeftContainer openViewer={openViewer} patientName={patientname} studies={studies}/>
    <div className="right-container"> 
      <div className="buttons-container">
        <Button.a href={"/api/report/download?t="+reportJWT} small icon="download">Save PDF</Button.a>
        <Button.a href={"/api/report/preview?t="+reportJWT} target="_blank" small highlight icon="print">Print</Button.a>
      </div>
      {
        finished &&
        <span className="last-save-info">
          Last updated: <Timer language={language} dateFormat={dateFormat} time={lastSaved} noPrefix alwaysDate/>
        </span>
      }
      {
        !finished &&
        <span className="last-save-info">
          <b>Report not finished.</b> Draft saved <Timer language={language} dateFormat={dateFormat} time={lastSaved}/>
        </span>
      }
    </div>
  </div>
}


const StudyLine = ({study}) => {
  const studyDateToDate = (date) => {
    const studyToDate = dicomDateToDate(date)
    return isNaN(studyToDate.getTime()) ? date : formatDate(studyToDate, 'L dd, yyyy', (type, index) => en_US[type + 's'][index])
  }
  return <>
    <span className="study-title">{study.title}</span> <span>({studyDateToDate(study.date)})</span>
  </>
}

const LeftContainer = ({openViewer, patientName, studies, readOnly}) =>
  <div className="left-container">
    <h2>Patient: {patientName}</h2>
    <div className="report-studies-list">
      <span>{studies.length > 1 ? 'Studies: ' : 'Study: '}</span>
      {studies.map(study => (
        study.url ?
        <a key={study.url} onClick={openViewer} disabled={readOnly}>
          <span className="icon icon-external-link-line"/>
          <StudyLine study={study}/>
        </a>
                  :
        <StudyLine key={study.title + study.date} study={study}/>
      ))}
    </div>
  </div>